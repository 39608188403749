import {Component, Injector, OnInit} from '@angular/core';
import {DeviceService} from '@shared/helpers/device.service';
import {AppComponentBase} from '@shared/app-component-base';

@Component({
    selector: 'app-coin-info-dialog',
    templateUrl: './coin-info-dialog.component.html',
    styleUrls: ['./coin-info-dialog.component.scss']
})
export class CoinInfoDialogComponent extends AppComponentBase {

    constructor(injector: Injector,
                private _device: DeviceService) {
        super(injector);
    }

    depositCoins() {
        if (!this._device.canBuy()) {
            this.message.warn(this.l('BuyingNotPossibleOnThisDevice'));
        } else {
            this._device.buy();
        }
    }
}
