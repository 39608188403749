import {Injectable} from '@angular/core';
import {MessageListDto, OverviewDto, OverviewServiceProxy} from '@shared/service-proxies/service-proxies';
import {Observable, Subject} from 'rxjs';
import {first} from '@node_modules/rxjs';

@Injectable({
    providedIn: 'root'
})
export class OverviewService {

    public static MessageModel = 'Message';

    public static ChatModel = 'Chat';

    public static EssayModel = 'Essay';

    public static UserModel = 'User';

    public static TenantModel = 'Tenant';

    public static RoleModel = 'Role';

    public static PostModel = 'Post';

    public onChange$: Observable<string>;

    private _overviewDto: OverviewDto;

    private _overview = new Subject<OverviewDto>();

    private _onChange = new Subject<string>();

    constructor(private _overviewService: OverviewServiceProxy) {
        this.onChange$ = this._onChange.asObservable();
    }

    getOverview(): Observable<OverviewDto> {
        this._overviewService
            .getOverview()
            .pipe(
                first(),
            )
            .subscribe(result => {
                this._overviewDto = result;
                this._overview.next(result);
            });

        return this._overview;
    }

    add(modelName: string, data: any) {
        if (!modelName || !data || !this._overviewDto) {
            this._onChange.next(modelName);
            return;
        }

        const list = this._overviewDto['latest' + modelName + 's'];
        if (list) {
            list.unshift(data);
            this._overview.next(this._overviewDto);
        }

        this._onChange.next(modelName);
    }

    delete(modelName: string, data: any) {
        if (!modelName || !data || !this._overviewDto) {
            this._onChange.next(modelName);
            return;
        }

        const list = this._overviewDto['latest' + modelName + 's'];
        if (list) {
            const dataInList = list.find(msg => msg.id === data.id);

            if (dataInList) {
                const index = list.indexOf(dataInList);
                list.splice(index, 1);
                this._overview.next(this._overviewDto);
            }
        }

        this._onChange.next(modelName);
    }
}
