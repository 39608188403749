import {
    Component,
    Injector,
    OnInit,
    EventEmitter,
    Output
} from '@angular/core';
import {AppComponentBase} from '@shared/app-component-base';
import {
    EssayServiceProxy,
    EssayDto, UpdateEssayDto,
} from '@shared/service-proxies/service-proxies';
import {FormBuilder, Validators} from '@angular/forms';
import {EditAvatarComponent} from '@app/avatars/edit-avatar/edit-avatar.component';

@Component({
    templateUrl: './edit-essay-dialog.component.html'
})
export class EditEssayDialogComponent extends AppComponentBase
    implements OnInit {
    saving = false;
    essay: EssayDto;
    id: string;

    @Output() onSave = new EventEmitter<any>();

    step1FormGroup = this._formBuilder.group({
        topic: ['', Validators.required],
        textNatureId: [0, Validators.required],
    });
    step2FormGroup = EditAvatarComponent.createForm(this._formBuilder);

    step3FormGroup = this._formBuilder.group({
        thirdCtrl: ['', Validators.required],
    });
    step4FormGroup = this._formBuilder.group({
        thirdCtrl: ['', Validators.required],
    });
    step5FormGroup = this._formBuilder.group({
        thirdCtrl: ['', Validators.required],
    });
    step6FormGroup = this._formBuilder.group({
        thirdCtrl: ['', Validators.required],
    });
    step7FormGroup = this._formBuilder.group({
        thirdCtrl: ['', Validators.required],
    });

    constructor(
        injector: Injector,
        public _essayService: EssayServiceProxy,
        private _formBuilder: FormBuilder
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._essayService
            .getAll(undefined, undefined, undefined, undefined)
            .subscribe(result => {
                if (!result.items || !result.items.length) {
                    this.essay = new EssayDto();
                } else {
                    this.essay = result.items[0];
                }

                this.step1FormGroup.setValue({
                    topic: this.essay.topic,
                    textNatureId: this.essay.textNatureId
                });
            });
        /*
        this._essayService.get(this.id).subscribe((result) => {
            this.essay = result;

            this.step1FormGroup.setValue({
                topic: this.essay.topic,
                textNatureId: this.essay.textNatureId
            });
        });
        */
    }

    save(): void {
        this.saving = true;

        const updateEssay = new UpdateEssayDto();
        this._essayService.update(updateEssay).subscribe(
            () => {
                this.notify.success(this.l('SavedSuccessfully'));
                this.onSave.emit();
            },
            () => {
                this.saving = false;
            }
        );
    }
}
