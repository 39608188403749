import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

    transform(value: moment.Moment): string {
        if (!value || typeof (value.format) !== 'function') {
            return '';
        }
        return value.format('DD.MM.yyyy HH:mm');
    }

}
