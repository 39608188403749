import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {AppConsts} from '@shared/AppConsts';

@Pipe({
    name: 'localizeHtml'
})
export class LocalizeHtmlPipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector,
                private _domSanitizer: DomSanitizer) {
        super(injector);
    }

    transform(key: string, ...args: any[]): SafeHtml {
        let html = this.l(key, ...args);
        if (args && args.length && args[0] === true) {
            html = html.replace(/%WEBBASEURL%/g, AppConsts.websiteBaseUrl);
        }
        return this._domSanitizer.bypassSecurityTrustHtml(html);
    }
}
