<mat-nav-list>
    <ng-container *ngFor="let category of categories">
        <div mat-subheader *ngIf="hasCategory">{{category.name}}</div>
        <mat-list-item class="mat-list-item-text"
                       *ngFor="let entry of category.entries" (click)="select(entry)" (click)="select(entry)">
            <img matListItemAvatar [src]="getImagePath(entry)" [alt]="entry.title" />
            <div matListItemTitle>{{ entry.title }}</div>
            <span *ngIf="showDescription">{{ entry.description }}</span>
        </mat-list-item>
    </ng-container>
</mat-nav-list>
