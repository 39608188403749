import {Component, OnInit} from '@angular/core';
import {VersionService} from './version.service';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`
})
export class RootComponent implements OnInit {
    constructor(private _versionService: VersionService) {
    }

    ngOnInit(): void {
        this._versionService.checkUpdate();
    }
}
