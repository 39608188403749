<h2 mat-dialog-title>
    {{'WriteWebsiteText' | localize}}
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <mat-stepper [linear]="true" cdkFocusInitial>
    </mat-stepper>
</mat-dialog-content>
