import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {environment} from '../src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {filter} from 'rxjs/operators';
import {catchError, first, map, of} from '@node_modules/rxjs';
import {DeviceService} from '@shared/helpers/device.service';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    currentVersion = environment.version;

    private _version = new BehaviorSubject<string>('');

    constructor(private _http: HttpClient,
                private _deviceService: DeviceService) {
        timer(10, 5 * 60 * 1000)
            .subscribe(() => {
                this.refreshVersion();
            });
    }

    checkUpdate(): void {
        abp.log.debug('check update...');
        const sub = this
            .isUpToDate()
            .pipe(
                first()
            )
            .subscribe((isUpToDate) => {
                if (!isUpToDate) {
                    abp.log.debug('update app...');
                    this._deviceService.refresh();
                }
            });
    }

    private isUpToDate(): Observable<boolean> {
        return this
            ._version
            .pipe(
                filter((version) => {
                    return version !== '';
                }),
                map((version) => {
                    return version === this.currentVersion;
                })
            );
    }

    private refreshVersion(): void {
        this
            ._http
            .get(`/environments/version.json?=${new Date().getTime()}`)
            .pipe(
                first(),
                catchError(() => {
                    return of({data: this.currentVersion});
                })
            )
            .subscribe((data: { version: string }) => {
                this._version.next(data.version);
            });
    }
}
