<h2 mat-dialog-title>
    {{'WriteEssay' | localize}}
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" *ngIf="essay">
    <mat-stepper [linear]="true" cdkFocusInitial orientation="vertical">
        <mat-step [stepControl]="step1FormGroup" [label]="'TopicAndTextNature' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step1FormGroup">
                <mat-form-field>
                    <mat-label>{{ "EssayTopic" | localize }}</mat-label>
                    <textarea matInput rows="3" formControlName="topic" required></textarea>
                </mat-form-field>
                <selection-controls formControlName="textNatureId"
                                    [catalogType]="'textTypes'" [catalogTypeName]="'Essay'"
                                    [categorieKey]="'TextNature'"></selection-controls>
                <div>
                    <button mat-stroked-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="step2FormGroup" [label]="'Avatar' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step2FormGroup">
                <app-edit-avatar [formGroup]="step2FormGroup"></app-edit-avatar>
                <div>
                    <button mat-stroked-button matStepperPrevious>{{"Back" | localize}}</button>
                    <button mat-stroked-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="step3FormGroup" [label]="'Questions' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step3FormGroup">
                <div>
                    <button mat-button matStepperPrevious>{{"Back" | localize}}</button>
                    <button mat-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="step4FormGroup" [label]="'EssayIntroduction' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step4FormGroup">
                <div>
                    <button mat-button matStepperPrevious>{{"Back" | localize}}</button>
                    <button mat-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="step5FormGroup" [label]="'EssayBody' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step5FormGroup">
                <div>
                    <button mat-button matStepperPrevious>{{"Back" | localize}}</button>
                    <button mat-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="step6FormGroup" [label]="'EssayConclusion' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step6FormGroup">
                <div>
                    <button mat-button matStepperPrevious>{{"Back" | localize}}</button>
                    <button mat-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="step7FormGroup" [label]="'EssayTitle' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step7FormGroup">
                <div>
                    <button mat-button matStepperPrevious>{{"Back" | localize}}</button>
                    <button mat-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <p>You are now done.</p>
            <div>
                <button mat-button matStepperPrevious>Back</button>
            </div>
        </mat-step>
    </mat-stepper>
</mat-dialog-content>
