import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
    name: 'replaceBreaks'
})
export class ReplaceBreaksPipe implements PipeTransform {

    constructor(private _domSanitizer: DomSanitizer) {
    }

    transform(value: string, ...args: any[]): SafeHtml {
        const html = value ? value.replace(/\n/g, '<br/>') : '';
        return this._domSanitizer.bypassSecurityTrustHtml(html);
    }

}
