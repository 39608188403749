import {Component, Input, OnInit} from '@angular/core';
import {DeviceService} from '@shared/helpers/device.service';
import {InfoDialogComponent} from '@shared/components/info-dialog/info-dialog.component';
import {DialogService} from '@shared/helpers/dialog.service';

@Component({
    selector: 'app-share-copy-text',
    templateUrl: './share-copy-text.component.html',
    styleUrls: ['./share-copy-text.component.scss']
})
export class ShareCopyTextComponent implements OnInit {
    @Input() text: string;

    public canShareText = false;

    public canCopyText = false;

    constructor(private _deviceService: DeviceService,
                private _dialog: DialogService) {
    }

    ngOnInit(): void {
        this.canShareText = this._deviceService.canShareText();
        this.canCopyText = this._deviceService.canCopyText();
    }

    shareText(text: string): void {
        this._deviceService.shareText(text);
    }

    copyText(text: string): void {
        this._deviceService.copyText(text);
    }

    showInfo(): void {
        this._dialog.openInfoDialog('InfoTextShareCopy_Title', 'InfoTextShareCopy_Text');
    }
}
