<h1 mat-dialog-title>
    <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h1>
<mat-dialog-content class="mat-typography" cdkFocusInitial>
    <ng-content></ng-content>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
    <button mat-stroked-button (click)="delete()">{{'Delete' | localize}}</button>
</mat-dialog-actions>

