import {BaseFormValues} from '../../../shared/settings/base-form-values';

export class CreateMessageFormValues extends BaseFormValues {
    formName = 'Form.CreateMessage';

    messageTopic: string = undefined;
    personTypeId: number = undefined;
    messageTypeId: number = undefined;
    textLength: number = undefined;
    ageCategoryId: number = undefined;
    tonalityId: number = undefined;
    languageId: number = undefined;

    constructor(json?: string) {
        super();

        this.fromJsonString(json);
    }
}
