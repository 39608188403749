<h2 mat-dialog-title>
    <button mat-icon-button class="back-button" (click)="back()" *ngIf="chatIsRunning">
        <mat-icon>arrow_back_ios_new</mat-icon>
    </button>
    {{'WritePost' | localize}}
    <button mat-icon-button class="close-button" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <mat-stepper [linear]="true" orientation="vertical" *ngIf="!chatIsRunning">
        <mat-step [stepControl]="step1FormGroup" [label]="'ContentAndReceiver' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step1FormGroup">
                <selection-controls formControlName="channelId"
                                    [catalogType]="'textTypes'" [catalogTypeName]="'Post'"
                                    [categorieKey]="'Channel'"></selection-controls>
                <selection-controls formControlName="targetGroupId" [showDescription]="false"
                                    [catalogType]="'textTypes'" [catalogTypeName]="'Post'"
                                    [categorieKey]="'TargetGroup'"></selection-controls>
                <selection-controls formControlName="postTypeId"
                                    [catalogType]="'textTypes'" [catalogTypeName]="'Post'"
                                    [categorieKey]="'PostType'"></selection-controls>
                <mat-form-field>
                    <mat-label>{{ 'TextLength' | localize }}</mat-label>
                    <mat-select formControlName="textLength">
                        <mat-option value="1">{{'TextLength_Short' | localize}}</mat-option>
                        <mat-option value="3">{{'TextLength_Medium' | localize}}</mat-option>
                        <mat-option value="2">{{'TextLength_Long' | localize}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "PostTopic" | localize }}</mat-label>
                    <textarea matInput rows="3" formControlName="postTopic" required></textarea>
                    <mat-error *ngIf="check.required(step1FormGroup.controls['postTopic'])"
                    >{{check.requiredMsg(step1FormGroup.controls['postTopic'])}}</mat-error>
                </mat-form-field>
                <div class="align-right">
                    <button mat-button color="primary" (click)="step1FormGroup.controls['postTopic'].setValue('')">
                        {{"ClearText" | localize}}</button>
                </div>
                <div>
                    <button mat-stroked-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="step2FormGroup" [label]="'Avatar' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <app-edit-avatar [formGroup]="step2FormGroup"></app-edit-avatar>
            <div>
                <button mat-stroked-button matStepperPrevious>{{"Back" | localize}}</button>
                <button mat-raised-button color="accent"
                        [disabled]="!canStartChat()" (click)="composePost()">{{"ComposePost" | localize}}</button>
            </div>
        </mat-step>
    </mat-stepper>
    <section *ngIf="chatIsRunning" class="chat">
        <mat-form-field [busyClass]="'PenAnimationClass' | localize" [busy]="isTyping">
            <textarea matInput rows="12" [(ngModel)]="composedPost"></textarea>
        </mat-form-field>
        <app-text-counter [text]="composedPost"></app-text-counter>
        <app-share-copy-text [text]="composedPost"></app-share-copy-text>
        <div>
            <button mat-raised-button color="accent"
                    [disabled]="!canStartChat()"
                    (click)="composePost()">{{"ReComposePost" | localize}}</button>
        </div>
    </section>
</mat-dialog-content>
