import {Injectable, OnDestroy} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import {LocalizeService} from '@shared/helpers/localize.service';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    OF_LABEL = 'of';

    constructor(private _localize: LocalizeService) {
        super();

        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.OF_LABEL = this._localize.l('PAGINATOR.OF_LABEL');
        this.itemsPerPageLabel = this._localize.l('PAGINATOR.ITEMS_PER_PAGE');
        this.nextPageLabel = this._localize.l('PAGINATOR.NEXT_PAGE');
        this.previousPageLabel = this._localize.l('PAGINATOR.PREVIOUS_PAGE');
        this.changes.next();
    }

    getRangeLabel = (
        page: number,
        pageSize: number,
        length: number,
    ) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OF_LABEL} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${
            this.OF_LABEL
        } ${length}`;
    };
}
