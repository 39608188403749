import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MessageDto, MessageServiceProxy} from '@shared/service-proxies/service-proxies';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ShowDialogBase} from '@shared/components/show-dialog/show-dialog-base';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-show-message-dialog',
    templateUrl: './show-message-dialog.component.html',
    styleUrls: ['./show-message-dialog.component.scss']
})
export class ShowMessageDialogComponent extends ShowDialogBase<MessageDto, ShowMessageDialogComponent> implements OnInit {

    constructor(injector: Injector,
                private _messageService: MessageServiceProxy,
                @Inject(MAT_DIALOG_DATA) private _data: { id: string },
                private _dialogRef: MatDialogRef<ShowMessageDialogComponent>) {
        super(injector, 'Message', _data.id, _dialogRef);
        this.data = new MessageDto();
    }

    ngOnInit(): void {
        this.onInit();
    }

    getData(): Observable<MessageDto> {
        return this._messageService.get(this._data.id);
    }

    deleteData(): Observable<void> {
        return this._messageService.delete(this._data.id);
    }

    public dataExists(): boolean {
        return !!(this.data && this.data.text);
    }
}
