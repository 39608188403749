import {AppComponentBase} from './app-component-base';
import {catchError, first, map, Observable, of, Subject, Subscription, tap} from '@node_modules/rxjs';
import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CurrentChatValues} from '@shared/settings/current-chat-values';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AiClientService} from '@shared/ai/ai-client.service';
import {AppSettingService} from '@shared/settings/app-setting.service';
import {DialogService} from '@shared/helpers/dialog.service';
import {OverviewService} from '@shared/session/overview.service';
import {MatDialogRef} from '@angular/material/dialog';
import {CloseOnBackHelper} from '@shared/helpers/close-on-back-helper';
import {BaseFormValues} from '@shared/settings/base-form-values';
import {StartChatReturn} from '@shared/ai/start-chat-return';
import {ValidationErrorsService} from '@shared/components/validation/validation-errors.service';
import {PointBalanceService} from '@shared/session/point-balance.service';
import {DeviceService} from '@shared/helpers/device.service';
import {ReceiveChatMessageDto} from '@shared/service-proxies/service-proxies';

@Component({
    template: ''
})
export abstract class ChatComponentBase<TValueModel> extends AppComponentBase implements OnInit, OnDestroy {
    isLoading = false;

    chatIsRunning = false;

    isTyping = false;

    prompts: ReceiveChatMessageDto[] = [];

    public check: ValidationErrorsService;

    protected currentChat: CurrentChatValues<TValueModel>;

    protected formBuilder: FormBuilder;

    protected aiClient: AiClientService;

    protected appSetting: AppSettingService;

    protected dialogService: DialogService;

    protected overviewService: OverviewService;

    protected abstract modelName: string;

    protected abstract formGroups: FormGroup[];

    protected abstract factory: () => BaseFormValues;

    protected abstract startChatFunc: (TValueModel) => Observable<StartChatReturn<TValueModel>>;

    protected abstract saveChatFunc: () => Observable<void>;

    private _subscription: Subscription;

    private _pointBalanceService: PointBalanceService;

    private _device: DeviceService;

    private _refreshCurrentChatCallback;

    private _changeDetectorRef: ChangeDetectorRef;

    protected constructor(injector: Injector,
                          private dialogRef: MatDialogRef<any>) {
        super(injector);
        this.formBuilder = injector.get(FormBuilder);
        this.aiClient = injector.get(AiClientService);
        this.appSetting = injector.get(AppSettingService);
        this.dialogService = injector.get(DialogService);
        this.overviewService = injector.get(OverviewService);
        this.check = injector.get(ValidationErrorsService);
        this._pointBalanceService = injector.get(PointBalanceService);
        this._device = injector.get(DeviceService);
        this._changeDetectorRef = injector.get(ChangeDetectorRef);

        CloseOnBackHelper.attach(this.dialogRef, () => this.close());

        this._refreshCurrentChatCallback = () => this.refreshCurrentChat();
    }

    ngOnInit(): void {
        this.appSetting.loadIntoForms(this.factory(), ...this.formGroups);
        this.refreshCurrentChat();

        abp.event.on('abp.signalr.connected', this._refreshCurrentChatCallback);
        abp.event.on('abp.signalr.reconnected', this._refreshCurrentChatCallback);
    }

    ngOnDestroy(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
        this._subscription = undefined;

        abp.event.off('abp.signalr.connected', this._refreshCurrentChatCallback);
        abp.event.off('abp.signalr.reconnected', this._refreshCurrentChatCallback);
    }

    canStartChat(): boolean {
        const invalids = this.formGroups.filter(fg => fg.invalid);
        return invalids.length === 0 && !this.isTyping && !this.isLoading;
    }

    startChat(): void {
        if (!this.canStartChat()) {
            return;
        }

        if (this._pointBalanceService.pointBalance <= 0) {
            this.dialogService.openConfirmDialog(this.l('NoCoinsLeftConfirmTitle'), this.l('NoCoinsLeftConfirmText'), (confirmed) => {
                if (confirmed) {
                    if (!this._device.canBuy()) {
                        this.message.warn(this.l('BuyingNotPossibleOnThisDevice'));
                    } else {
                        this._device.buy();
                    }
                }
            });
            return;
        }

        const data = this.factory();
        this.appSetting.saveFromForms(data, ...this.formGroups);

        this.prompts = [];
        this.chatIsRunning = true;
        this.isTyping = true;
        this.startChatFunc(data)
            .pipe(
                first(),
                catchError(error => {
                    this.chatIsRunning = false;
                    this.isTyping = false;
                    throw error;
                })
            )
            .subscribe(result => {
                this.currentChat = result.chat;
                this._receiveMessages(result.receiveMessage);
            });
    }

    loadChat(chat: CurrentChatValues<TValueModel>): void {
        if (!chat || !chat.chatId) {
            return;
        }

        this.chatIsRunning = true;
        this.isLoading = true;
        this.isTyping = true;
        this.aiClient
            .getChat(chat)
            .pipe(
                first(),
                map(result => {
                    if (result && result.messages) {
                        return result;
                    }

                    this.dialogService
                        .openConfirmDialog('CouldNotLoad' + this.modelName + '_Title',
                            'CouldNotLoad' + this.modelName + '_Text', confirmed => {
                                if (confirmed) {
                                    this.chatIsRunning = false;
                                    this.isLoading = false;
                                    this.isTyping = false;
                                } else {
                                    this.finalize(true);
                                }
                            });
                    return {chat: undefined, messages: [], receiveMessage: null, completed: true};
                }),
                map(result => {
                    this.currentChat = result.chat;
                    this.prompts = result.messages;
                    this.isTyping = this.prompts.length === 0;
                    this.isLoading = false;
                    this._receiveMessages(result.receiveMessage);
                })
            )
            .subscribe();
    }

    sendMessage(text: string): void {
        const chatMessage = new ReceiveChatMessageDto({chatId: '', role: 'user', message: text, completed: true});
        this.prompts.push(chatMessage);
        this.isTyping = true;

        this.aiClient
            .sendChatMessage(this.currentChat.chatId, text)
            .pipe(
                first(),
                catchError(error => {
                    this.isTyping = false;
                    this.back();
                    throw error;
                })
            )
            .subscribe();
    }

    back(): void {
        if (!this.chatIsRunning) {
            return;
        }

        this.dialogService
            .openConfirmDialog(this.modelName, 'ConfirmBack' + this.modelName, (confirmed) => {
                if (confirmed === true) {
                    this.finalize(false);
                    this.chatIsRunning = false;
                }
            });
    }

    close(): void {
        if (this.chatIsRunning) {
            this.dialogService
                .openConfirmDialog(this.modelName, 'ConfirmSaving' + this.modelName, (confirmed) => {
                    if (confirmed === true) {
                        this.saveChatFunc()
                            .subscribe(() => {
                                this.finalize();
                                this.notify.success(this.l('SavedSuccessfully'));
                            });
                    } else if (confirmed === false) {
                        this.finalize();
                    }
                }, true);
            return;
        }

        this.finalize();
    }

    protected onReceived = () => {
    };

    protected finalize(close = true): void {
        if (this.currentChat && this.currentChat.chatId) {
            this.aiClient.endChat(this.currentChat).subscribe();
        }
        this.appSetting.deleteCurrentChat(this.modelName);

        if (close) {
            this.dialogRef.close();
        }

        this.isTyping = false;
        this.isLoading = false;
    }

    private _receiveMessages(receiveMessage: Subject<ReceiveChatMessageDto>) {
        if (!receiveMessage) {
            return;
        }

        if (this._subscription) {
            this._subscription.unsubscribe();
        }
        this._subscription = receiveMessage
            .subscribe(message => {
                if (message && message.message) {
                    this.prompts.push(message);
                }
                this.isTyping = false;
                this.isLoading = false;
                this.onReceived();
                this._changeDetectorRef.detectChanges();
            }, () => {
                this.chatIsRunning = false;
                this.finalize(true);
                this._changeDetectorRef.detectChanges();
                this.message.error(this.l('VizemacherIsOverloaded'));
            });

        this.onReceived();
    }

    private refreshCurrentChat(): void {
        this.currentChat = this.appSetting.loadCurrentChat(this.modelName);
        if (this.currentChat) {
            this.loadChat(this.currentChat);
        }
    }
}
