import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    showCancelButton = false;
    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, text: string, showCancelButton?: boolean }) {
        if (data.showCancelButton) {
            this.showCancelButton = data.showCancelButton;
        }
    }
}
