import {Component, Inject, Injector, OnInit} from '@angular/core';
import {ShowDialogBase} from '@shared/components/show-dialog/show-dialog-base';
import {PostDto, PostServiceProxy} from '@shared/service-proxies/service-proxies';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, tap} from '@node_modules/rxjs';
import {DtoMapperService} from '@shared/helpers/dto-mapper.service';

@Component({
  selector: 'app-show-post-dialog',
  templateUrl: './show-post-dialog.component.html',
  styleUrls: ['./show-post-dialog.component.scss']
})
export class ShowPostDialogComponent extends ShowDialogBase<PostDto, ShowPostDialogComponent> implements OnInit {
    channelImageUrl: string;

    constructor(injector: Injector,
                private _postService: PostServiceProxy,
                private _dtoMapperService: DtoMapperService,
                @Inject(MAT_DIALOG_DATA) private _data: { id: string },
                private _dialogRef: MatDialogRef<ShowPostDialogComponent>) {
        super(injector, 'Post', _data.id, _dialogRef);
        this.data = new PostDto();
    }

    ngOnInit(): void {
        this.onInit();
    }

    getData(): Observable<PostDto> {
        return this._postService
            .get(this._data.id)
            .pipe(
                tap(result => {
                    this.channelImageUrl = this._dtoMapperService.buildChannelLogoUrl(result.channelId);
                })
            );
    }

    deleteData(): Observable<void> {
        return this._postService.delete(this._data.id);
    }

    public dataExists(): boolean {
        return !!(this.data && this.data.text);
    }
}
