<app-show-dialog (onDelete)="delete()">
    <div *ngIf="dataExists()">
        <h2>{{topic}}</h2>
        <span>{{data.lastModificationTime | momentDate}}</span>
    </div>
    <section class="chat" *ngIf="dataExists()" [busy]="isLoading">
        <div class="chat-display">
            <div *ngFor="let prompt of data.messages" class="{{prompt.role + '-text'}}">
                <span [innerHTML]="prompt.message | replaceBreaks"></span>
            </div>
        </div>
    </section>
</app-show-dialog>
