<h2 mat-dialog-title *ngIf="!chatIsRunning">
    {{'QuickChat' | localize}}
    <button mat-icon-button class="close-button" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<h2 mat-dialog-title *ngIf="chatIsRunning" [ngClass]="{'live-header': chatIsRunning}">
    <img src="/assets/img/avatar_chat_toright.png" class="img-circle"/>
    <div>
        <span>{{'MeinTextAiName' | localize}}</span>
        <span>{{(isTyping ? 'IsTyping' : 'Online') | localize}}</span>
    </div>
    <button mat-icon-button class="close-button" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <mat-stepper [linear]="true" cdkFocusInitial orientation="vertical" *ngIf="!chatIsRunning">
        <mat-step [stepControl]="step1FormGroup" [label]="'ChatTopic' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <form [formGroup]="step1FormGroup">
                <selection-controls formControlName="chatTopicId" [showDescription]="false"
                                    [catalogType]="'textTypes'" [catalogTypeName]="'Chat'"
                                    [categorieKey]="'ChatTopic'" [hasCategories]="true"></selection-controls>
                <div>
                    <button mat-stroked-button matStepperNext>{{"Next" | localize}}</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="step2FormGroup" [label]="'Avatar' | localize"
                  [errorMessage]="'FillAllFields' | localize">
            <app-edit-avatar [formGroup]="step2FormGroup"></app-edit-avatar>
            <div>
                <button mat-stroked-button matStepperPrevious>{{"Back" | localize}}</button>
                <button mat-raised-button color="accent"
                        [disabled]="!canStartChat()" (click)="startChat()">{{"StartChat" | localize}}</button>
            </div>
        </mat-step>
    </mat-stepper>
    <section *ngIf="chatIsRunning" class="chat">
        <div class="chat-display" #chatDisplayEl [busy]="isLoading">
            <div *ngFor="let prompt of prompts; index as index" class="{{prompt.role+'-text'}}">
                <span>
                    <span [innerHTML]="prompt.message | replaceBreaks"></span>
                    <span *ngIf="!isTyping && (index === prompts.length - 1) && !prompt.completed">
                        <b>{{'WriteMoreQuestion' | localize}}</b>&nbsp;&nbsp;
                        <button mat-flat-button color="primary" (click)="continueWriting()">{{'Yes' | localize}}</button>
                    </span>
                </span>
            </div>
            <div class="loading-text" *ngIf="isTyping">
                <span><i class="fas fa-spinner animate-spin"></i></span>
            </div>
        </div>
        <div class="input-bar">
            <textarea class="no-resize" placeholder="{{'StartTyping' | localize}}" (keydown)="onKeydown($event)"
                      [(ngModel)]="textInput" #inputEl></textarea>
            <button mat-icon-button color="accent" (click)="sendMessage()"
                    [disabled]="!textInput || isTyping">
                <mat-icon>send</mat-icon>
            </button>
        </div>
    </section>
</mat-dialog-content>
