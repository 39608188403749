import {BaseFormValues} from '@shared/settings/base-form-values';

export class QuickChatFormValues extends BaseFormValues {
    formName = 'Form.QuickChat';

    chatTopicId: number = undefined;
    ageCategoryId: number = undefined;
    tonalityId: number = undefined;
    languageId: number = undefined;

    constructor(json?: string) {
        super();

        this.fromJsonString(json);
    }
}
