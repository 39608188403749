import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectionItem} from '@shared/components/selection/selection-item';
import {SelectDialogComponent} from '@shared/components/selection/select-dialog/select-dialog.component';
import {BsModalService} from '@node_modules/ngx-bootstrap/modal';
import {LookupDataFilterService} from '@shared/session/lookup-data-filter.service';
import {AppComponentBase} from '@shared/app-component-base';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {InfoDialogComponent} from '@shared/components/info-dialog/info-dialog.component';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {Subscription} from '@node_modules/rxjs';
import {SelectionCategory} from '@shared/components/selection/selection-category';
import {SelectDialogData} from '@shared/components/selection/select-dialog-data';
import {DialogService} from '@shared/helpers/dialog.service';

@Component({
    selector: 'selection-controls',
    templateUrl: './selection-controls.component.html',
    styleUrls: ['./selection-controls.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectionControlsComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SelectionControlsComponent
        }
    ]
})
export class SelectionControlsComponent extends AppComponentBase
    implements OnInit, OnDestroy, ControlValueAccessor, Validator {

    @Input() catalogType: string;

    @Input() catalogTypeName: string;

    @Input() categorieKey: string;

    @Input() showDescription = true;

    @Input() hasCategories = false;

    @Output() selectedIdChange = new EventEmitter<number | string>();

    entries: SelectionItem[];

    categories: SelectionCategory[] = [];

    selectedEntry: SelectionItem;

    selectedId: number | string;

    touched = false;

    disabled = false;

    _subscriptions: Subscription[] = [];

    constructor(private _injector: Injector,
                private _modalService: BsModalService,
                private _lookupFilterService: LookupDataFilterService,
                private _bottomSheet: MatBottomSheet,
                private dialog: DialogService) {
        super(_injector);
    }

    onChange = (item) => {
    };

    onTouched = () => {
    };

    ngOnInit() {
        this.entries = this._lookupFilterService
            .getCatalogEntriesForSelection(this.catalogType, this.catalogTypeName, this.categorieKey);
        if (!this.entries.length) {
            return;
        }

        this.entries.forEach(entry => {
            entry.title = this.l('selection_title_' + entry.key + '_' + entry.value);
            entry.description = this.l('selection_description_' + entry.key + '_' + entry.value);
        });

        const categories: SelectionCategory[] = [];
        if (this.hasCategories) {
            this.entries.forEach(entry => {
                if (!entry.title) {
                    return;
                }

                let categoryName;
                const pos = entry.title.indexOf(':');
                if (pos < 1) {
                    categoryName = '';
                } else {
                    categoryName = entry.title.substring(0, pos);
                    entry.title = entry.title.substring(pos + 1);
                }

                const results = categories.filter(cat => cat.name === categoryName);
                let category;
                if (!results.length) {
                    category = {
                        name: categoryName,
                        entries: [entry]
                    };
                    categories.push(category);
                } else {
                    results[0].entries.push(entry);
                }
            });
        } else {
            categories.push({
                name: '',
                entries: this.entries
            });
        }

        this.categories = categories;

        this.selectEntry();

        if (!this.selectedEntry) {
            setTimeout(() => {
                if (!this.selectedEntry) {
                    this.writeValue(this.entries[0].id);
                    this.onChange(this.selectedId);
                }
            }, 50);
        }
    }

    ngOnDestroy() {
        if (this._subscriptions && this._subscriptions.length) {
            this._subscriptions.forEach(sub => sub.unsubscribe());
            this._subscriptions = [];
        }
    }

    getImagePath(entry: SelectionItem): string {
        if (!entry) {
            return null;
        }
        return `/assets/selections/${entry.key}_${entry.value}.png`;
    }

    showSelectDialog(): void {
        const data: SelectDialogData = {
            categories: this.categories,
            showDescription: this.showDescription,
            hasCategory: this.hasCategories
        };

        const ref = this._bottomSheet.open(SelectDialogComponent, {
            data: data
        });

        const sub = ref.afterDismissed()
            .subscribe(result => {
                this.markAsTouched();

                if (!result) {
                    return;
                }

                if (this.disabled) {
                    return;
                }

                this.selectedEntry = result;
                if (this.selectedEntry.id) {
                    this.selectedId = this.selectedEntry.id;
                } else {
                    this.selectedId = undefined;
                }
                this.onChange(this.selectedId);
            });
        this._subscriptions.push(sub);
    }

    showInfo(): void {
        this.dialog.openInfoDialog(this.categorieKey, 'info_text_' + this.categorieKey);
    }

    selectEntry(): void {
        if (!this.entries || !this.entries.length) {
            return;
        }

        const result = this.entries.filter(x => x.id === this.selectedId);
        if (result.length > 0) {
            this.selectedEntry = result[0];
        }
    }

    writeValue(id: number | string) {
        this.selectedId = id;
        this.selectEntry();
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const id = control.value;
        if (id <= 0) {
            return {
                required: true
            };
        }
    }

    getRowCount(): number {
        const charCount = this.selectedEntry && this.selectedEntry.title
            ? this.selectedEntry.title.length
            : 0;

        if (charCount < 20) {
            return 1;
        }

        if (charCount < 40) {
            return 2;
        }

        return 3;
    }
}
