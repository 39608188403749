import {Injectable} from '@angular/core';
import {NotifyService} from 'abp-ng2-module';
import {LocalizeService} from '@shared/helpers/localize.service';
import {AndroidDevice} from '@shared/helpers/android-device';
import {TokenService} from '@node_modules/abp-ng2-module';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    android: AndroidDevice;

    _login: { username: string, password: string };

    constructor(private _notify: NotifyService,
                private _localize: LocalizeService,
                private _tokenService: TokenService) {
        this.android = (<any>window).Android;
    }

    shareText(text: string, title = ''): void {
        if (this.android && typeof (this.android.share) === 'function') {
            try {
                this.android.share(title, text);
            } catch (ex) {
                this._notify.error(this._localize.l('CouldNotShareText'));
                abp.log.error(ex);
            }
            return;
        }

        navigator
            .share({
                text: text,
                title: title
            })
            .catch(error => {
                this._notify.error(this._localize.l('CouldNotShareText'));
                abp.log.error(error);
            });
    }

    copyText(text: string): void {
        if (this.android && typeof (this.android.copyToClipboard) === 'function') {
            try {
                this.android.copyToClipboard(text);
            } catch (ex) {
                this._notify.error(this._localize.l('CouldNotShareText'));
                abp.log.error(ex);
            }
            return;
        }

        navigator
            .clipboard
            .writeText(text)
            .then(() => {
                this._notify.info(this._localize.l('CopyToClipboardSucceed'));
            })
            .catch(error => {
                this._notify.error(this._localize.l('CopyToClipboardFailed'));
                abp.log.error(error);
            });
    }

    buy() {
        if (this.android && typeof (this.android.showBilling) === 'function') {
            try {
                const token = this._tokenService.getToken();
                this.android.showBilling(`Bearer ${token}`);
            } catch (ex) {
                this._notify.error(this._localize.l('CouldNotStartBuying'));
                abp.log.error(ex);
            }
            return;
        }

        alert(this._localize.l('BuyingNotPossibleOnThisDevice'));
    }

    canCopyText() {
        return (navigator && navigator.clipboard && typeof (navigator.clipboard.writeText) === 'function') ||
            (this.android && typeof (this.android.copyToClipboard) === 'function');
    }

    canShareText() {
        return (navigator && typeof (navigator.share) === 'function') ||
            (this.android && typeof (this.android.share) === 'function');
    }

    canBuy() {
        return (this.android && typeof (this.android.showBilling) === 'function');
    }

    canAutoLogin() {
        return (this.android && typeof (this.android.saveLogin) === 'function'
            && typeof (this.android.loadLogin) === 'function');
    }

    saveLogin(username: string, password: string): boolean {
        if (this.android && typeof (this.android.saveLogin) === 'function') {
            try {
                return this.android.saveLogin(username, password);
            } catch (ex) {
                abp.log.error(ex);
            }
        }

        this._login = {
            username: username,
            password: password
        };

        return true;
    }

    loadLogin(): { username: string, password: string } {
        if (this.android && typeof (this.android.loadLogin) === 'function') {
            try {
                const json = this.android.loadLogin();
                if (!json) {
                    return undefined;
                }

                const data = JSON.parse(json);
                return {
                    username: data.username,
                    password: data.password,
                };
            } catch (ex) {
                abp.log.error(ex);
                return null;
            }
        }

        if (this._login && this._login.username) {
            return {
                username: this._login.username,
                password: this._login.password
            };
        }

        return null;
    }

    refresh() {
        if (this.android && typeof (this.android.refresh) === 'function') {
            try {
                this.android.refresh();
            } catch (ex) {
                abp.log.error(ex);
            }
            return;
        }
        document.location.replace('/app/home?nc=' + new Date().getTime());
    }
}

