// "Production" enabled environment

import {VERSION} from './version';

export const environment = {
    production: true,
    hmr: false,
    appConfig: 'appconfig.stage.json',
    version: VERSION.version,
    remoteServiceBaseUrl: 'https://meintextapi.reacsys.de',
};

console.log('stage: ' + VERSION.version);
