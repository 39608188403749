import {MatDialogRef} from '@angular/material/dialog';

export class CloseOnBackHelper {
    static attach(dialogRef: MatDialogRef<any>, closeFunc: () => void): void {
        (<any>dialogRef).closeOnBack = () => closeFunc();
    }

    static close(dialogRef: MatDialogRef<any>): void {
        if (typeof ((<any>dialogRef).closeOnBack) === 'function') {
            (<any>dialogRef).closeOnBack();
        } else {
            dialogRef.close();
        }
    }
}
