import {Component, ElementRef, Injector, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {EditAvatarComponent} from '@app/avatars/edit-avatar/edit-avatar.component';
import {catchError, first, map} from '@node_modules/rxjs';
import {QuickChatFormValues} from '@app/quick-chats/quick-chat-dialog/quick-chat-form-values';
import {MatDialogRef} from '@angular/material/dialog';
import {
    ChatListDto,
    ChatMessageDto,
    ChatServiceProxy,
    CreateChatDto
} from '@shared/service-proxies/service-proxies';
import {OverviewService} from '@shared/session/overview.service';
import {ChatComponentBase} from '@shared/chat-component-base';
import {MessageTypeNames} from '@shared/settings/message-type-names';

@Component({
    templateUrl: './quick-chat-dialog.component.html',
    styleUrls: ['./quick-chat-dialog.component.scss', '../../../shared/chat-component-base.scss']
})
export class QuickChatDialogComponent extends ChatComponentBase<QuickChatFormValues> {
    @ViewChild('chatDisplayEl') chatDisplayEl: ElementRef;

    @ViewChild('inputEl') inputEl: ElementRef;

    step1FormGroup = this.formBuilder.group({
        chatTopicId: [0, Validators.required]
    });

    step2FormGroup = EditAvatarComponent.createForm(this.formBuilder);

    textInput = '';

    protected formGroups = [this.step1FormGroup, this.step2FormGroup];

    protected modelName = MessageTypeNames.QuickChat;

    constructor(injector: Injector,
                dialogRef: MatDialogRef<QuickChatDialogComponent>,
                private _chatService: ChatServiceProxy) {
        super(injector, dialogRef);
    }

    sendMessage(): void {
        const text = this.textInput;
        this.textInput = '';
        this.inputEl.nativeElement.focus();
        this._scrollChatDisplay();
        super.sendMessage(text);
    }

    continueWriting(): void {
        this.isTyping = true;

        this.aiClient
            .continueWritingChatMessage(this.currentChat.chatId)
            .pipe(
                first(),
                catchError(error => {
                    this.isTyping = false;
                    throw error;
                })
            )
            .subscribe();
    }

    onKeydown(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            if (!this.textInput || this.isTyping) {
                return;
            }
            this.sendMessage();
            event.preventDefault();
            event.stopPropagation();
        }
    }

    protected factory = () => new QuickChatFormValues();

    protected startChatFunc = data => {
        return this.aiClient
            .quickChat(data.chatTopicId, data.ageCategoryId, data.tonalityId, data.languageId);
    }

    protected saveChatFunc = () => {
        const createDto = new CreateChatDto();
        createDto.init(this.currentChat.model);
        createDto.topicId = this.currentChat.model.chatTopicId;

        if (this.prompts.length > 2) {
            createDto.description = this.prompts[1].message + ' - ' + this.prompts[2].message;
        } else if (this.prompts.length > 1) {
            createDto.description = this.prompts[1].message;
        } else if (this.prompts.length > 0) {
            createDto.description = this.prompts[0].message;
        } else {
            createDto.description = '';
        }

        createDto.messages = this.prompts
            .map(prompt => new ChatMessageDto(prompt));
        return this._chatService
            .create(createDto)
            .pipe(
                map(chatDto => {
                    const listDto = new ChatListDto();
                    listDto.init(chatDto);
                    this.overviewService.add(OverviewService.ChatModel, listDto);
                })
            );
    }

    protected onReceived = () => {
        this._scrollChatDisplay();
        this.inputEl.nativeElement.focus();
    }

    private _scrollChatDisplay(): void {
        setTimeout(() => {
            this.chatDisplayEl.nativeElement.scrollTo({
                top: this.chatDisplayEl.nativeElement.scrollHeight,
                behavior: 'smooth'
            });
        }, 100);
    }
}
