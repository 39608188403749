<form [formGroup]="formGroup">
    <selection-controls formControlName="ageCategoryId"
                        [catalogType]="'aiTypes'" [catalogTypeName]="'ChatGPT'"
                        [categorieKey]="'AgeCategory'"></selection-controls>
    <selection-controls formControlName="tonalityId"
                        [catalogType]="'aiTypes'" [catalogTypeName]="'ChatGPT'"
                        [categorieKey]="'Tonality'"></selection-controls>
    <selection-controls formControlName="languageId" [showDescription]="false"
                        [catalogType]="'aiTypes'" [catalogTypeName]="'ChatGPT'"
                        [categorieKey]="'Language'"></selection-controls>
</form>
