import {Injectable} from '@angular/core';
import {PermissionCheckerService} from 'abp-ng2-module';
import {AppSessionService} from '../session/app-session.service';

import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild
} from '@angular/router';
import {DeviceService} from '@shared/helpers/device.service';
import {AppAuthService} from '@shared/auth/app-auth.service';
import {Observable, of} from '@node_modules/rxjs';
import {Subject} from 'rxjs';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private _device: DeviceService,
        private _authService: AppAuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this._sessionService.user) {
            if (this._device.canAutoLogin()) {
                const credentials = this._device.loadLogin();
                if (credentials && credentials.username) {
                    this._authService.authenticateModel.userNameOrEmailAddress = credentials.username;
                    this._authService.authenticateModel.password = credentials.password;
                    const subject = new Subject<boolean>();
                    this._authService.authenticate(authenticated => {
                        subject.next(authenticated);
                        if (!authenticated) {
                            this._router.navigate(['/account/login']);
                        }
                    });
                    return subject;
                }
            }

            this._router.navigate(['/account/login']);
            return of(false);
        }

        if (!route.data || !route.data['permission']) {
            return of(true);
        }

        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return of(true);
        }

        this._router.navigate([this.selectBestRoute()]);
        return of(false);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted('Pages.Users')) {
            return '/app/admin/users';
        }

        return '/app/home';
    }
}
