<div *ngIf="!!text">
    <button mat-icon-button color="primary" aria-label="" *ngIf="canShareText"
            (click)="shareText(text)">
        <mat-icon>share</mat-icon>
    </button>
    <button mat-icon-button color="primary" aria-label="" *ngIf="canCopyText"
            (click)="copyText(text)">
        <mat-icon>content_copy</mat-icon>
    </button>
    <button mat-icon-button matSuffix (click)="showInfo()">
        <mat-icon>help_outline</mat-icon>
    </button>
</div>
