import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-compact-card',
    templateUrl: './compact-card.component.html',
    styleUrls: ['./compact-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompactCardComponent implements OnChanges {
    @Input() text: string;

    @Input() footer: string;

    @Input() logoUrl: string;

    @Output() onSelect = new EventEmitter<any>();

    html: string;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['text'] || changes['logoUrl']) {
            let html = this.text;
            if (this.logoUrl) {
                html = `<img src="${this.logoUrl}" height="38px">` + html;
            }
            this.html = html;
        }
    }

    select(): void {
        this.onSelect.emit();
    }
}
