import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-text-counter',
    templateUrl: './text-counter.component.html',
    styleUrls: ['./text-counter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextCounterComponent {
    @Input() text: string;

    countChars(): number {
        if (!this.text) {
            return 0;
        }
        return this.text.length;
    }

    countWords(): number {
        if (!this.text) {
            return 0;
        }
        return this.text.split(' ').length;
    }
}
