import {FormGroup} from '@angular/forms';

export class BaseFormValues {
    formName = undefined;

    constructor() {
    }

    fromJsonString(json: string): void {
        if (!json) {
            return;
        }

        const data = JSON.parse(json);
        Object.keys(this).forEach(key => {
            this[key] = data[key];
        });
    }

    toJsonString(): string {
        return JSON.stringify(this);
    }

    toForms(...forms: FormGroup[]): void {
        // Properties müssen einen Wert (z.B.undefined) haben, damit es funktioniert.
        Object.keys(this).forEach(key => {
            for (let index = 0; index < forms.length; index++) {
                const form = forms[index];
                if (form.controls[key]) {
                    form.controls[key].setValue(this[key]);
                    break;
                }
            }
        });
    }

    fromForms(...forms: FormGroup[]): void {
        // Properties müssen einen Wert (z.B.undefined) haben, damit es funktioniert.
        Object.keys(this).forEach(key => {
            for (let index = 0; index < forms.length; index++) {
                const form = forms[index];
                if (form.controls[key]) {
                    this[key] = form.controls[key].value;
                    break;
                }
            }
        });
    }

    init(data: any): void {
        for (const property in data) {
            if (this.hasOwnProperty(property)) {
                this[property] = data[property];
            }
        }
    }
}
