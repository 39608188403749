export class CurrentChatValues<TModel> {
    chatId: string;
    messageType: string;
    model: TModel;

    constructor(data?: any) {
    }

    init(_data?: any) {
        if (_data) {
            this.chatId = _data['chatId'];
            this.messageType = _data['messageType'];
        }
    }
}
