import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CountdownEvent} from '@node_modules/ngx-countdown';
import {CountdownConfig} from '@node_modules/ngx-countdown/interfaces';

@Component({
  selector: 'app-reconnect-dialog',
  templateUrl: './reconnect-dialog.component.html',
  styleUrls: ['./reconnect-dialog.component.scss']
})
export class ReconnectDialogComponent {
    config: CountdownConfig = {
        demand: false
    };
    constructor(@Inject(MAT_DIALOG_DATA) public data: { seconds: number }) {
        if (data) {
            this.config.leftTime = data.seconds;
        }
    }


    handleEvent($event: CountdownEvent) {
    }
}
