import {Injectable} from '@angular/core';
import {ChatListDto, EssayListDto, MessageListDto, PostListDto} from '@shared/service-proxies/service-proxies';
import {LookupDataFilterService} from '@shared/session/lookup-data-filter.service';
import {LocalizeService} from '@shared/helpers/localize.service';
import {ScrollContainerEntry} from '@app/home/scroll-container/scroll-container-entry';

@Injectable({
    providedIn: 'root'
})
export class DtoMapperService {

    constructor(private _lookupData: LookupDataFilterService,
                private _localizationService: LocalizeService) {
    }

    buildMessageText(message: MessageListDto): string {
        if (message.topic) {
            return '<b>' + message.topic + '</b>: ' + message.text;
        }
        return message.text;
    }

    buildPostText(post: PostListDto): string {
        if (post.topic) {
            return '<b>' + post.topic + '</b>: ' + post.text;
        }
        return post.text;
    }

    buildChatText(chat: ChatListDto) {
        const entry = this._lookupData
            .getCatalogEntry('textTypes', 'Chat', 'ChatTopic', chat.topicId);

        if (entry) {
            let translation = this._localizationService.l('selection_title_' + entry.key + '_' + entry.value);
            if (translation.indexOf(':') > 0) {
                translation = translation.substring(translation.indexOf(':') + 1);
            }
            return '<b>' + translation + '</b>: ' + chat.description;
        }
        return chat.description;
    }


    buildEssayText(essay: EssayListDto) {
        return essay.topic;
    }

    buildChannelLogoUrl(channelId: number): string {
        const entry = this._lookupData
            .getCatalogEntry('textTypes', 'Post', 'Channel', channelId);
        if (!entry) {
            return '';
        }
        const logoUrl = `/assets/selections/${entry.key}_${entry.value}.png`;
        return logoUrl;
    }

    messageListDtoToScrollContainerEntry(messages: MessageListDto[]): ScrollContainerEntry[] {
        return messages
            .map(msg => {
                return {
                    id: msg.id,
                    text: this.buildMessageText(msg),
                    lastModificationTime: msg.lastModificationTime
                };
            });
    }

    chatListDtoToScrollContainerEntry(chats: ChatListDto[]): ScrollContainerEntry[] {
        return chats
            .map(chat => {
                return {
                    id: chat.id,
                    text: this.buildChatText(chat),
                    lastModificationTime: chat.lastModificationTime
                };
            });
    }

    essayListDtoToScrollContainerEntry(essays: EssayListDto[]): ScrollContainerEntry[] {
        return essays
            .map(essay => {
                return {
                    id: essay.id,
                    text: this.buildEssayText(essay),
                    lastModificationTime: essay.lastModificationTime
                };
            });
    }

    postListDtoToScrollContainerEntry(posts: PostListDto[]): ScrollContainerEntry[] {
        return posts
            .map(post => {
                return {
                    id: post.id,
                    text: this.buildPostText(post),
                    lastModificationTime: post.lastModificationTime,
                    logoUrl: this.buildChannelLogoUrl(post.channelId)
                };
            });
    }
}
