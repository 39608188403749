import {CommonModule} from '@angular/common';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';

import {AppSessionService} from './session/app-session.service';
import {AppUrlService} from './nav/app-url.service';
import {AppAuthService} from './auth/app-auth.service';
import {AppRouteGuard} from './auth/app-route-guard';
import {LocalizePipe} from '@shared/pipes/localize.pipe';

import {AbpPaginationControlsComponent} from './components/pagination/abp-pagination-controls.component';
import {AbpValidationSummaryComponent} from './components/validation/abp-validation.summary.component';
import {AbpModalHeaderComponent} from './components/modal/abp-modal-header.component';
import {AbpModalFooterComponent} from './components/modal/abp-modal-footer.component';
import {LayoutStoreService} from './layout/layout-store.service';

import {BusyDirective} from './directives/busy.directive';
import {EqualValidator} from './directives/equal-validator.directive';
import {LookupDataFilterService} from '@shared/session/lookup-data-filter.service';
import {SelectionControlsComponent} from '@shared/components/selection/selection-controls.component';
import {SelectDialogComponent} from '@shared/components/selection/select-dialog/select-dialog.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {LocalizeHtmlPipe} from '@shared/pipes/localize-html.pipe';
import {MaterialModule} from '@shared/material/material.module';
import {InfoDialogComponent} from './components/info-dialog/info-dialog.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidationErrorsService} from '@shared/components/validation/validation-errors.service';
import {AvatarCtoaComponent} from './components/avatar-ctoa/avatar-ctoa.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {AiClientService} from '@shared/ai/ai-client.service';
import {MomentDatePipe} from './pipes/moment-date.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {ShowDialogComponent} from '@shared/components/show-dialog/show-dialog.component';
import {CompactCardComponent} from './components/compact-card/compact-card.component';
import {CoinInfoDialogComponent} from './components/coin-info-dialog/coin-info-dialog.component';
import {ReplaceBreaksPipe} from './pipes/replace-breaks.pipe';
import { ShareCopyTextComponent } from './components/share-copy-text/share-copy-text.component';
import { ReconnectDialogComponent } from './components/reconnect-dialog/reconnect-dialog.component';
import {CountdownModule} from '@node_modules/ngx-countdown';
import { AnimationOverlayComponent } from './animations/animation-overlay/animation-overlay.component';
import { TextCounterComponent } from './components/text-counter/text-counter.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        CollapseModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CountdownModule
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        LocalizeHtmlPipe,
        BusyDirective,
        EqualValidator,
        SelectionControlsComponent,
        SelectDialogComponent,
        InfoDialogComponent,
        AvatarCtoaComponent,
        ConfirmDialogComponent,
        MomentDatePipe,
        SafeHtmlPipe,
        ShowDialogComponent,
        CompactCardComponent,
        CoinInfoDialogComponent,
        ReplaceBreaksPipe,
        ShareCopyTextComponent,
        ReconnectDialogComponent,
        AnimationOverlayComponent,
        TextCounterComponent,
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        LocalizeHtmlPipe,
        MomentDatePipe,
        BusyDirective,
        EqualValidator,
        SelectionControlsComponent,
        AvatarCtoaComponent,
        SafeHtmlPipe,
        ShowDialogComponent,
        CompactCardComponent,
        ReplaceBreaksPipe,
        ShareCopyTextComponent,
        AnimationOverlayComponent,
        TextCounterComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
                LookupDataFilterService,
                ValidationErrorsService,
                AiClientService
            ]
        };
    }
}
