import {Injectable} from '@angular/core';
import {ChangeCurrentChatInput, ConfigurationServiceProxy, StoreFormDefaultValueInput} from '@shared/service-proxies/service-proxies';
import {BaseFormValues} from '@shared/settings/base-form-values';
import {SettingService} from 'abp-ng2-module';
import {FormGroup} from '@angular/forms';
import {first} from '@node_modules/rxjs';
import {BehaviorSubject, Observable} from 'rxjs';
import {CurrentChatValues} from '@shared/settings/current-chat-values';
import {QuickChatFormValues} from '@app/quick-chats/quick-chat-dialog/quick-chat-form-values';
import {CreateMessageFormValues} from '@app/messages/create-message-dialog/create-message-form-values';
import {CreatePostFormValues} from '@app/social-medias/create-post-dialog/create-post-form-values';
import {MessageTypeNames} from '@shared/settings/message-type-names';

@Injectable({
    providedIn: 'root'
})
export class AppSettingService {

    currentChat$: Observable<CurrentChatValues<any>[]>;

    private currentChatSubject: BehaviorSubject<CurrentChatValues<any>[]>;

    constructor(private _settingService: SettingService,
                private _configService: ConfigurationServiceProxy) {
        this.currentChatSubject = new BehaviorSubject<CurrentChatValues<any>[]>(this._loadCurrentChats());
        this.currentChat$ = this.currentChatSubject.asObservable();
    }

    refreshCurrentChats(): void {
        this.currentChatSubject.next(this._loadCurrentChats());
    }

    loadCurrentChat<TModel>(messageType: string): CurrentChatValues<TModel> {
        if (!messageType) {
            throw new Error('Argument messageType missing.');
        }
        const settingName = 'CurrentChat.' + messageType;
        const json = this._settingService.get(settingName);
        if (!json) {
            return undefined;
        }

        const input = <ChangeCurrentChatInput>JSON.parse(json);
        const values = new CurrentChatValues<TModel>();
        values.init(input);
        if (input.value) {
            values.model = <TModel>JSON.parse(input.value);
        }
        return values;
    }

    deleteCurrentChat<TModel>(messageType: string): void {
        if (!messageType) {
            throw new Error('Argument messageType missing.');
        }
        const input = new CurrentChatValues();
        input.messageType = messageType;
        this.saveCurrentChat(input);
    }

    saveCurrentChat<TModel>(values: CurrentChatValues<TModel>): void {
        if (!values.messageType) {
            throw new Error('Argument messageType missing.');
        }
        const settingName = 'CurrentChat.' + values.messageType;
        const input = new ChangeCurrentChatInput();
        input.init(values);
        if (values.model) {
            input.value = JSON.stringify(values.model);
        }

        abp.setting.values[settingName] = input ? JSON.stringify(input) : undefined;
        this.refreshCurrentChats();

        this._configService
            .changeCurrentChat(input)
            .pipe(first())
            .subscribe();
    }

    loadIntoForms(formValues: BaseFormValues, ...forms: FormGroup[]) {
        const values = this._settingService.get(formValues.formName);
        if (!values) {
            return;
        }
        formValues.fromJsonString(values);
        formValues.toForms(...forms);
    }

    saveFromForms(formValues: BaseFormValues, ...forms: FormGroup[]): BaseFormValues {
        formValues.fromForms(...forms);

        const input = new StoreFormDefaultValueInput();
        input.formName = formValues.formName;
        input.value = formValues.toJsonString();

        abp.setting.values[input.formName] = input.value;

        this._configService
            .storeDefaultFormValues(input)
            .pipe(first())
            .subscribe();

        return formValues;
    }

    private _loadCurrentChats(): CurrentChatValues<any>[] {
        const chats: CurrentChatValues<any>[] = [];
        const loaders = [
            () => this.loadCurrentChat<QuickChatFormValues>(MessageTypeNames.QuickChat),
            () => this.loadCurrentChat<CreateMessageFormValues>(MessageTypeNames.Message),
            () => this.loadCurrentChat<CreatePostFormValues>(MessageTypeNames.Post)
        ];
        loaders.forEach(loader => {
            const chat = loader();
            if (chat) {
                chats.push(chat);
            }
        });
        return chats;
    }
}
