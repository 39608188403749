import {Injector, OnInit} from '@angular/core';
import {DeviceService} from '../../helpers/device.service';
import {AppComponentBase} from '../../app-component-base';
import {MatDialogRef} from '@angular/material/dialog';
import {finalize} from 'rxjs/operators';
import {DialogService} from '../../helpers/dialog.service';
import {OverviewService} from '../../session/overview.service';
import {catchError, first, of, Observable} from 'rxjs';

export abstract class ShowDialogBase<TModel, TDialog> extends AppComponentBase {

    modelName: string;

    id: string;

    data: TModel;

    dialogRef: MatDialogRef<TDialog>;

    isLoading = false;

    canShareText = false;

    canCopyText = false;

    device: DeviceService;

    overview: OverviewService;

    dialog: DialogService;

    protected constructor(injector: Injector,
                          modelName: string,
                          id: string,
                          dialogRef: MatDialogRef<TDialog>) {
        super(injector);
        this.device = injector.get(DeviceService);
        this.overview = injector.get(OverviewService);
        this.dialog = injector.get(DialogService);
        this.id = id;
        this.dialogRef = dialogRef;
        this.modelName = modelName;
    }

    abstract getData(): Observable<TModel>;

    abstract deleteData(): Observable<void>;

    abstract dataExists(): boolean;

    onInit(): void {
        this.canShareText = this.device.canShareText();
        this.canCopyText = this.device.canCopyText();

        if (!this.id) {
            this._showErrorMessage();
            return;
        }

        this.isLoading = true;

        this.getData()
            .pipe(
                first(),
                catchError(error => {
                    this._showErrorMessage();
                    return of(undefined);
                }),
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe((data: TModel) => {
                this.data = data;
            });
    }

    delete(): void {
        this.dialog
            .openConfirmDialog(this.modelName, 'ConfirmDeletingText', (confirmed) => {
                if (confirmed) {
                    this.deleteData()
                        .pipe(
                            first()
                        )
                        .subscribe(() => {
                            this.overview.delete(this.modelName, this.data);
                            this.dialogRef.close();
                            this.notify.success(this.l('SuccessfullyDeleted'));
                        });
                }
            });
    }

    shareText(text: string): void {
        this.device.shareText(text);
    }

    copyText(text: string): void {
        this.device.copyText(text);
    }

    private _showErrorMessage(): void {
        const errorMessage = this.l('CouldNotLoadEntity', this.l(this.modelName));
        this.message.error(errorMessage);
        this.dialogRef.close();
    }
}
