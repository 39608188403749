import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TokenService, LogService, UtilsService} from 'abp-ng2-module';
import {AppConsts} from '@shared/AppConsts';
import {UrlHelper} from '@shared/helpers/UrlHelper';
import {
    AccountServiceProxy,
    AuthenticateModel,
    AuthenticateResultModel, ResendTokenInput, SendPasswordResetCodeInput, SetNewPasswordDto, SetNewPasswordResultDto,
    TokenAuthServiceProxy, VerifyTokenInput,
} from '@shared/service-proxies/service-proxies';
import {first, Observable} from '@node_modules/rxjs';
import {DeviceService} from '@shared/helpers/device.service';

@Injectable()
export class AppAuthService {
    authenticateModel: AuthenticateModel;
    authenticateResult: AuthenticateResultModel;
    rememberMe: boolean;

    constructor(
        private _tokenAuthService: TokenAuthServiceProxy,
        private _router: Router,
        private _utilsService: UtilsService,
        private _tokenService: TokenService,
        private _logService: LogService,
        private _accountService: AccountServiceProxy,
        private _device: DeviceService) {
        this.clear();
    }

    logout(reload?: boolean): void {
        abp.auth.clearToken();
        abp.utils.deleteCookie(AppConsts.authorization.encryptedAuthTokenName);

        if (this._device.canAutoLogin()) {
            this._device.saveLogin('', '');
        }

        if (reload !== false) {
            location.href = AppConsts.appBaseUrl;
        }
    }

    resendToken(): Observable<void> {
        const input = new ResendTokenInput();
        input.email = this.authenticateModel.userNameOrEmailAddress;
        return this._accountService.resendToken(input);
    }

    verifyToken(token: string): Observable<void> {
        const model = new VerifyTokenInput();
        model.token = token;
        return this._accountService.verifyToken(model);
    }

    resetPassword(email: string): Observable<void> {
        const model = new SendPasswordResetCodeInput();
        model.email = email;
        return this._accountService.sendPasswordResetCode(model);
    }

    setNewPassword(token: string, password: string): Observable<SetNewPasswordResultDto> {
        const model = new SetNewPasswordDto();
        model.token = token;
        model.newPassword = password;
        return this._accountService.setNetPassword(model);
    }

    authenticate(finallyCallback?: (authenticated: boolean) => void): void {
        finallyCallback = finallyCallback || ((authenticated) => {
        });

        this._tokenAuthService
            .authenticate(this.authenticateModel)
            .pipe(
                first()
            )
            .subscribe((result: AuthenticateResultModel) => {
                this.processAuthenticateResult(result);
                finallyCallback(!!result.accessToken);
            }, () => {
                finallyCallback(false);
            });
    }

    private processAuthenticateResult(
        authenticateResult: AuthenticateResultModel
    ) {
        this.authenticateResult = authenticateResult;

        if (authenticateResult.accessToken) {
            this._device.saveLogin(this.authenticateModel.userNameOrEmailAddress, this.authenticateModel.password);

            // Successfully logged in
            this.login(
                authenticateResult.accessToken,
                authenticateResult.encryptedAccessToken,
                authenticateResult.expireInSeconds,
                this.rememberMe
            );
        } else if (authenticateResult.userEmailIsNotConfirmed) {
            this._device.saveLogin(this.authenticateModel.userNameOrEmailAddress, this.authenticateModel.password);
            this._router.navigate(['/account/welcome'], {replaceUrl: true});
        } else {
            // Unexpected result!

            this._logService.warn('Unexpected authenticateResult!');
            this._router.navigate(['account/login']);
        }
    }

    private login(
        accessToken: string,
        encryptedAccessToken: string,
        expireInSeconds: number,
        rememberMe?: boolean
    ): void {
        const tokenExpireDate = rememberMe
            ? new Date(new Date().getTime() + 1000 * expireInSeconds)
            : undefined;

        this._tokenService.setToken(accessToken, tokenExpireDate);

        this._utilsService.setCookieValue(
            AppConsts.authorization.encryptedAuthTokenName,
            encryptedAccessToken,
            tokenExpireDate,
            abp.appPath
        );

        let initialUrl = UrlHelper.initialUrl;
        if (initialUrl.indexOf('/account') > 0) {
            initialUrl = AppConsts.appBaseUrl;
        }

        location.href = initialUrl;
    }

    private clear(): void {
        this.authenticateModel = new AuthenticateModel();
        this.authenticateModel.rememberClient = true;
        this.authenticateResult = null;
        this.rememberMe = false;
    }
}
