import {Component, Injector, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {EditAvatarComponent} from '@app/avatars/edit-avatar/edit-avatar.component';
import {
    CreatePostDto,
    PostListDto,
    PostServiceProxy
} from '@shared/service-proxies/service-proxies';
import {OverviewService} from '@shared/session/overview.service';
import {MatDialogRef} from '@angular/material/dialog';
import {first, map} from '@node_modules/rxjs';
import {CreatePostFormValues} from '@app/social-medias/create-post-dialog/create-post-form-values';
import {ChatComponentBase} from '@shared/chat-component-base';
import {MessageTypeNames} from '@shared/settings/message-type-names';

@Component({
    templateUrl: './create-post-dialog.component.html',
    styleUrls: ['./create-post-dialog.component.scss', '../../../shared/chat-component-base.scss']
})
export class CreatePostDialogComponent extends ChatComponentBase<CreatePostFormValues> implements OnInit {

    step1FormGroup = this.formBuilder.group({
        channelId: [0, Validators.required],
        postTypeId: [0, Validators.required],
        targetGroupId: [0, Validators.required],
        postTopic: ['', Validators.required],
        textLength: ['3', Validators.required]
    });

    step2FormGroup = EditAvatarComponent.createForm(this.formBuilder);

    composedPost = '';

    protected formGroups = [this.step1FormGroup, this.step2FormGroup];

    protected modelName = MessageTypeNames.Post;

    constructor(injector: Injector,
                dialogRef: MatDialogRef<CreatePostDialogComponent>,
                private _postService: PostServiceProxy) {
        super(injector, dialogRef);
    }

    composePost(): void {
        if (this.currentChat && this.currentChat.chatId) {
            this.finalize(false);
        }
        this.startChat();
    }

    protected factory = () => new CreatePostFormValues();

    protected startChatFunc = data => {
        return this.aiClient
            .composePost(data.postTopic, data.postTypeId, data.channelId, data.targetGroupId,
                Number(data.textLength), data.ageCategoryId, data.tonalityId, data.languageId);
    };

    protected saveChatFunc = () => {
        const createDto = new CreatePostDto();
        createDto.init(this.currentChat.model);
        createDto.topic = this.currentChat.model.postTopic;
        createDto.text = this.composedPost;

        return this._postService
            .create(createDto)
            .pipe(
                first(),
                map(postDto => {
                    const listDto = new PostListDto();
                    listDto.init(postDto);
                    this.overviewService.add(OverviewService.PostModel, listDto);
                })
            );
    };

    protected onReceived = () => {
        const asisstantPrompts = this.prompts
            .filter(prompt => prompt.role === 'assistant');
        this.composedPost = '';
        asisstantPrompts
            .forEach(prompt => this.composedPost += prompt.message);
    }
}
