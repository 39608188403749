<app-show-dialog (onDelete)="delete()">
    <ng-container *ngIf="dataExists()">
        <div>
            <img *ngIf="channelImageUrl" [src]="channelImageUrl" />
            <h2>{{data.topic}}</h2>
            <span>{{data.lastModificationTime | momentDate}}</span>
            <mat-form-field [busy]="isLoading">
                <textarea matInput rows="12" [(ngModel)]="data.text"></textarea>
            </mat-form-field>
        </div>
        <app-text-counter [text]="data.text"></app-text-counter>
        <app-share-copy-text [text]="data.text"></app-share-copy-text>
    </ng-container>
</app-show-dialog>
