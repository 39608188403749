import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {EditEssayDialogComponent} from '@app/essays/edit-essay-dialog/edit-essay-dialog.component';
import {QuickChatDialogComponent} from '@app/quick-chats/quick-chat-dialog/quick-chat-dialog.component';
import {EditWebsiteTextDialogComponent} from '@app/websites/edit-website-text-dialog/edit-website-text-dialog.component';
import {CreateMessageDialogComponent} from '@app/messages/create-message-dialog/create-message-dialog.component';
import {CreatePostDialogComponent} from '@app/social-medias/create-post-dialog/create-post-dialog.component';
import {ConfirmDialogComponent} from '@shared/components/confirm-dialog/confirm-dialog.component';
import {ShowMessageDialogComponent} from '@app/messages/show-message-dialog/show-message-dialog.component';
import {ShowQuickChatDialogComponent} from '@app/quick-chats/show-quick-chat-dialog/show-quick-chat-dialog.component';
import {ShowPostDialogComponent} from '@app/social-medias/show-post-dialog/show-post-dialog.component';
import {ReconnectDialogComponent} from '@shared/components/reconnect-dialog/reconnect-dialog.component';
import {InfoDialogComponent} from '@shared/components/info-dialog/info-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private _stepperDialogConfig: MatDialogConfig = {
        disableClose: true,
        closeOnNavigation: false,
        maxWidth: '1024px',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: ['full-screen-dialog', 'stepper-dialog']
    };

    private _fullScreenConfig: MatDialogConfig = {
        disableClose: true,
        closeOnNavigation: false,
        maxWidth: '1024px',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: ['full-screen-dialog'],
        data: {}
    };

    private _paddingScreenConfig = {
        maxWidth: '1024px',
        maxHeight: '100vh',
        height: 'calc(100% - 2em)',
        width: 'calc(100% - 2em)',
        panelClass: ['full-screen-dialog', 'padding-screen-dialog']
    };

    private _infoScreenConfig = {
        maxWidth: '800px',
        width: 'calc(100% - 2em)',
        data: {
            infoText: undefined,
            title: undefined
        }
    };

    constructor(private dialog: MatDialog) {
    }

    openDialog(component: any): void {
        this.dialog.open(component, this._paddingScreenConfig);
    }

    openReconnectDialog(seconds: number): MatDialogRef<any> {
        const ref = this.dialog.open(ReconnectDialogComponent, {
            data: {
                seconds: seconds
            },
            disableClose: true,
            closeOnNavigation: false
        });
        return ref;
    }

    openMessageDialog(id?: string): void {
        if (id) {
            const config = {...this._fullScreenConfig};
            config.data.id = id;
            this.dialog.open(ShowMessageDialogComponent, config);
        } else {
            this.dialog.open(CreateMessageDialogComponent, this._stepperDialogConfig);
        }
    }

    openPostDialog(id?: string): void {
        if (id) {
            const config = {...this._fullScreenConfig};
            config.data.id = id;
            this.dialog.open(ShowPostDialogComponent, config);
        } else {
            this.dialog.open(CreatePostDialogComponent, this._stepperDialogConfig);
        }
    }

    openWebsiteTextDialog(): void {
        const ref = this.dialog.open(EditWebsiteTextDialogComponent, this._stepperDialogConfig);
        this._attachConfirmOnClose(ref);
    }

    openQuickChatDialog(id?: string): void {
        if (id) {
            const config = {...this._fullScreenConfig};
            config.data = {id:  id};
            this.dialog.open(ShowQuickChatDialogComponent, config);
        } else {
            this.dialog.open(QuickChatDialogComponent, this._stepperDialogConfig);
        }
    }

    openEssayDialog(id?: string): void {
        if (id) {
            const config = {...this._fullScreenConfig};
            config.data = {id:  id};
            this.dialog.open(EditEssayDialogComponent, config);
        } else {
            const ref = this.dialog.open(EditEssayDialogComponent, this._stepperDialogConfig);
            this._attachConfirmOnClose(ref);
        }
    }

    openConfirmDialog(title: string, text: string, action: (confirmed: boolean) => void, showCancelButton?: boolean): void {
        const ref = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: title,
                text: text,
                showCancelButton: showCancelButton
            },
            closeOnNavigation: false
        });

        const sub = ref
            .afterClosed()
            .subscribe((confirmed: boolean) => {
                if (action) {
                    action(confirmed);
                }
                sub.unsubscribe();
            });
    }

    openInfoDialog(title: string, text: string): void {
        const config = {...this._infoScreenConfig};
        config.data = {
            infoText: text,
            title: title
        };
        this.dialog.open(InfoDialogComponent, config);
    }

    private _attachConfirmOnClose(ref: MatDialogRef<any, any>): void {
        const refClose = ref.close;
        ref.close = (result) => {
            this.openConfirmDialog('CloseText_Title', 'CloseText_Text',
                (confirmed) => {
                    if (confirmed) {
                        refClose.call(ref, result);
                    }
                });
        };
    }
}
