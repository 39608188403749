import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatBadgeModule} from '@angular/material/badge';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatRippleModule} from '@angular/material/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {CustomMatPaginatorIntl} from '@shared/material/custom-mat-paginator-intl';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatToolbarModule,
        MatMenuModule,
        MatBottomSheetModule,
        MatListModule,
        MatDialogModule,
        MatStepperModule,
        MatSelectModule,
        MatExpansionModule,
        MatGridListModule,
        MatCardModule,
        MatBadgeModule,
        ScrollingModule,
        MatRippleModule,
        MatPaginatorModule,
        MatCheckboxModule
    ],
    exports: [
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatToolbarModule,
        MatMenuModule,
        MatBottomSheetModule,
        MatListModule,
        MatDialogModule,
        MatStepperModule,
        MatSelectModule,
        MatExpansionModule,
        MatGridListModule,
        MatCardModule,
        MatBadgeModule,
        ScrollingModule,
        MatRippleModule,
        MatPaginatorModule,
        MatCheckboxModule
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {showError: true}
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {appearance: 'outline'}
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
    ]
})
export class MaterialModule {
}
