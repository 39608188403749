import {Injectable} from '@angular/core';
import {AppConsts} from '@shared/AppConsts';
import {LocalizationService} from '@node_modules/abp-ng2-module';

@Injectable({
    providedIn: 'root'
})
export class LocalizeService {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    constructor(private _localization: LocalizationService) {
    }

    l(key: string): string {
        return this._localization.localize(key, this.localizationSourceName);
    }
}
