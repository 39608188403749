import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {AppConsts} from '@shared/AppConsts';
import {LocalizationService} from '@node_modules/abp-ng2-module';

const defaultErrors = {
    required: {
        name: 'required', localizationKey: 'ThisFieldIsRequired'
    },
    minlength: {
        name: 'minlength',
        localizationKey: 'PleaseEnterAtLeastNCharacter',
        propertyKey: 'requiredLength',
    },
    maxlength: {
        name: 'maxlength',
        localizationKey: 'PleaseEnterNoMoreThanNCharacter',
        propertyKey: 'requiredLength',
    },
    email: {
        name: 'email',
        localizationKey: 'InvalidEmailAddress',
    },
    pattern: {
        name: 'pattern',
        localizationKey: 'InvalidPattern',
        propertyKey: 'requiredPattern',
    },
    validateEqual: {
        name: 'validateEqual',
        localizationKey: 'PairsDoNotMatch',
    },
};

@Injectable({
    providedIn: 'root'
})
export class ValidationErrorsService {

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    constructor(private localization: LocalizationService) {
    }

    required(control: AbstractControl): boolean {
        return this.hasError(defaultErrors.required.name, control);
    }

    requiredMsg(control: AbstractControl): string {
        return this.getErrorMessage(defaultErrors.required.name, control);
    }

    maxLength(control: AbstractControl): boolean {
        return this.hasError(defaultErrors.maxlength.name, control);
    }

    maxLengthMsg(control: AbstractControl): string {
        return this.getErrorMessage(defaultErrors.maxlength.name, control);
    }

    minLength(control: AbstractControl): boolean {
        return this.hasError(defaultErrors.minlength.name, control);
    }

    minLengthMsg(control: AbstractControl): string {
        return this.getErrorMessage(defaultErrors.minlength.name, control);
    }

    email(control: AbstractControl): boolean {
        return this.hasError(defaultErrors.email.name, control);
    }

    emailMsg(control: AbstractControl): string {
        return this.getErrorMessage(defaultErrors.email.name, control);
    }

    pattern(control: AbstractControl): boolean {
        return this.hasError(defaultErrors.pattern.name, control);
    }

    patternMsg(control: AbstractControl, localizationKey?: string): string {
        return this.getErrorMessage(defaultErrors.pattern.name, control, localizationKey);
    }

    equal(control: AbstractControl): boolean {
        return this.hasError(defaultErrors.validateEqual.name, control);
    }

    equalMsg(control: AbstractControl, localizationKey?: string): string {
        return this.getErrorMessage(defaultErrors.validateEqual.name, control, localizationKey);
    }

    equalValidator(resolveControl: () => FormControl): ValidatorFn {
        return (control: AbstractControl) => {
            const value1 = control.value;
            let control2;
            try {
                control2 = resolveControl();
            } catch {
                return null;
            }
            const value2 = control2.value;
            if (value1 !== value2) {
                return {
                    validateEqual: {}
                };
            }
            return null;
        };
    }

    private hasError(errorName: string, control: AbstractControl): boolean {
        return control.hasError(errorName);
    }

    private getErrorMessage(errorName: string, control: AbstractControl, localizationKey?: string): string {
        const error = defaultErrors[errorName];
        const propertyValue = control.errors[error.name][error.propertyKey];
        const localKey = localizationKey ? localizationKey : error.localizationKey;
        return !!propertyValue
            ? this.l(localKey, propertyValue)
            : this.l(localKey);
    }

    private l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }
}
