import {Injectable} from '@angular/core';
import {AppSessionService} from '@shared/session/app-session.service';
import {SelectionItem} from '@shared/components/selection/selection-item';
import {CatalogEntryDto} from '@shared/service-proxies/service-proxies';

@Injectable()
export class LookupDataFilterService {

    _orderOfCatalog = {
        'AgeCategory': [
            'HighSchoolGraduates',
            'Professionals',
            'Students',
            'Seniors',
            'VocationalStudents',
            'Academics',
            'SecondarySchoolPupils',
            'PrimarySchoolPupils'
        ],
        'Tonality': [
            'Informal'
        ],
        'Language': [
            'German'
        ],
        'ChatTopic': [
            'Small_Talk'
        ],
        'PostType': [
            'Inspiration'
        ],
    };

    constructor(private _sessionService: AppSessionService) {
    }

    getCatalog(catalogType: string, catalogTypeName: string, categoryKey: string): CatalogEntryDto[] {
        const types = this._sessionService
            .lookupData[catalogType]
            .filter(x => x.name === catalogTypeName);

        if (types.length < 1) {
            return [];
        }

        const entries = types[0]
            .catalogEntries
            .filter(entry => entry.key === categoryKey);

        const sortedEntries = [];
        let unsortedEntries = [];
        if (this._orderOfCatalog[categoryKey]) {
            this._orderOfCatalog[categoryKey].forEach(key => {
                const result = entries.filter(x => x.value === key);
                if (result.length > 0) {
                    sortedEntries.push(result[0]);
                }
            });
            unsortedEntries = entries.filter(x => this._orderOfCatalog[categoryKey].indexOf(x.value) < 0);
        } else {
            unsortedEntries = entries;
        }

        sortedEntries.push(...unsortedEntries);

        return sortedEntries;
    }

    getCatalogEntriesForSelection(catalogType: string, catalogTypeName: string, categoryKey: string): SelectionItem[] {
        return this
            .getCatalog(catalogType, catalogTypeName, categoryKey)
            .map(entry => {
                return {
                    id: entry.id,
                    key: entry.key,
                    value: entry.value,
                    item: entry
                };
            });
    }

    getCatalogEntry(catalogType: string, catalogTypeName: string, categoryKey: string, id: number): SelectionItem {
        const filtered = this
            .getCatalog(catalogType, catalogTypeName, categoryKey)
            .filter(entry => entry.id === id)
            .map(entry => {
                return {
                    id: entry.id,
                    key: entry.key,
                    value: entry.value,
                    item: entry
                };
            });

        if (filtered.length) {
            return filtered[0];
        }
        return undefined;
    }
}
