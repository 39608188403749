import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AppSettingService} from '@shared/settings/app-setting.service';
import {
    AiServiceProxy, ClientErrorServiceProxy,
    ComposeMessageDto, ComposePostDto,
    ContinueWritingChatMessageDto, LogErrorDto, QuickChatDto, ReceiveChatMessageDto,
    SendChatMessageDto
} from '@shared/service-proxies/service-proxies';
import {CurrentChatValues} from '@shared/settings/current-chat-values';
import {QuickChatFormValues} from '@app/quick-chats/quick-chat-dialog/quick-chat-form-values';
import {MessageTypeNames} from '@shared/settings/message-type-names';
import {StartChatReturn} from '@shared/ai/start-chat-return';
import {CreatePostFormValues} from '@app/social-medias/create-post-dialog/create-post-form-values';
import {CreateMessageFormValues} from '@app/messages/create-message-dialog/create-message-form-values';
import {catchError, first, map, of} from '@node_modules/rxjs';
import {finalize} from 'rxjs/operators';
import {DialogService} from '@shared/helpers/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class AiClientService {

    private _chats = {};

    constructor(private _appSetting: AppSettingService,
                private _aiService: AiServiceProxy,
                private _clientError: ClientErrorServiceProxy,
                private _dialog: DialogService) {
        abp.event.on('abp.notifications.received', (notification) => {
            if (notification.notification.notificationName !== 'ChatMessage') {
                return;
            }

            let chatMessage: ReceiveChatMessageDto;
            const properties = notification.notification.data.properties;
            if (properties.Error) {
                const error = new LogErrorDto({
                    message: properties.Error,
                    stackTrace: undefined
                });

                this._clientError
                    .logError(error)
                    .pipe(
                        first(),
                        catchError(ex => {
                            abp.log.error(ex);
                            return of();
                        })
                    )
                    .subscribe();

                abp.log.error(error);

                chatMessage = new ReceiveChatMessageDto();
                chatMessage.chatId = properties.ChatId;
            } else {
                chatMessage = new ReceiveChatMessageDto({
                    role: properties.Role,
                    message: properties.Message,
                    chatId: properties.ChatId,
                    completed: properties.Completed
                });
            }

            const subject = this._chats[chatMessage.chatId] as Subject<ReceiveChatMessageDto>;
            if (!subject) {
                abp.log.debug('Chat subject ' + chatMessage.chatId + ' is missing.');
                return;
            }

            if (properties.Error) {
                subject.error(properties.Error);
            } else {
                subject.next(chatMessage);
            }
        });
    }

    sendChatMessage(chatId: string, text: string): Observable<void> {
        const args = new SendChatMessageDto({
            chatId: chatId,
            message: text
        });

        return this._aiService.sendChatMessage(args);
    }

    continueWritingChatMessage(chatId: string): Observable<void> {
        const args = new ContinueWritingChatMessageDto({
            chatId: chatId
        });

        return this._aiService.continueWritingChatMessage(args);
    }

    quickChat(chatTopicId: number, ageCategoryId: number, tonalityId: number,
              languageId: number): Observable<StartChatReturn<QuickChatFormValues>> {
        const args = new QuickChatDto({
            aiModelId: undefined,
            chatTopicId: chatTopicId,
            ageCategoryId: ageCategoryId,
            tonalityId: tonalityId,
            languageId: languageId
        });

        return this._aiService
            .quickChat(args)
            .pipe(
                map(chatId => {
                    const subject = new Subject<ReceiveChatMessageDto>();
                    this._chats[chatId] = subject;
                    const chat = new CurrentChatValues<QuickChatFormValues>();
                    chat.chatId = chatId;
                    chat.messageType = MessageTypeNames.QuickChat;
                    chat.model = new QuickChatFormValues();
                    chat.model.init(args);
                    this._appSetting.saveCurrentChat(chat);
                    return {chat: chat, receiveMessage: subject};
                })
            );
    }

    getChat(chat: CurrentChatValues<any>): Observable<{
        chat: CurrentChatValues<any>, messages: ReceiveChatMessageDto[],
        receiveMessage: Subject<ReceiveChatMessageDto>
    }> {
        return this._aiService
            .getChat(chat.chatId)
            .pipe(
                catchError(error => {
                    this._appSetting.deleteCurrentChat(chat.messageType);
                    throw error;
                }),
                map(result => {
                    if (!result) {
                        this._appSetting.deleteCurrentChat(chat.messageType);
                    }
                    const subject = new Subject<ReceiveChatMessageDto>();
                    this._chats[chat.chatId] = subject;
                    this._appSetting.saveCurrentChat(chat);
                    const messages = result ? result.messages : null;
                    return {chat: chat, messages: messages, receiveMessage: subject};
                })
            );
    }

    endChat(values: CurrentChatValues<any>): Observable<void> {
        if (!values.chatId) {
            throw new Error('Argument chatId missing.');
        }
        values.model = undefined;
        this._appSetting.deleteCurrentChat(values.messageType);

        return this._aiService
            .endChat(values.chatId)
            .pipe(
                catchError(error => {
                    abp.log.error(error);
                    return of(null);
                }),
                finalize(() => {
                    if (this._chats[values.chatId]) {
                        delete (this._chats[values.chatId]);
                    }
                })
            );
    }

    composeMessage(messageTopic: string, messageTypeId: number, personTypeId: number, textLength: number,
                   ageCategoryId: number, tonalityId: number, languageId: number): Observable<StartChatReturn<CreateMessageFormValues>> {
        const args = new ComposeMessageDto({
            aiModelId: undefined,
            messageTopic: messageTopic,
            messageTypeId: messageTypeId,
            personTypeId: personTypeId,
            textLength: textLength,
            ageCategoryId: ageCategoryId,
            tonalityId: tonalityId,
            languageId: languageId
        });

        return this._aiService
            .composeMessage(args)
            .pipe(
                map(chatId => {
                    const subject = new Subject<ReceiveChatMessageDto>();
                    this._chats[chatId] = subject;
                    const chat = new CurrentChatValues<CreateMessageFormValues>();
                    chat.chatId = chatId;
                    chat.messageType = MessageTypeNames.Message;
                    chat.model = new CreateMessageFormValues();
                    chat.model.init(args);
                    this._appSetting.saveCurrentChat(chat);
                    return {chat: chat, receiveMessage: subject};
                })
            );
    }

    composePost(postTopic: string, postTypeId: number, channelId: number, targetGroupId: number,
                textLength: number, ageCategoryId: number, tonalityId: number,
                languageId: number): Observable<StartChatReturn<CreatePostFormValues>> {
        const args = new ComposePostDto({
            aiModelId: undefined,
            postTopic: postTopic,
            postTypeId: postTypeId,
            channelId: channelId,
            targetGroupId: targetGroupId,
            textLength: textLength,
            ageCategoryId: ageCategoryId,
            tonalityId: tonalityId,
            languageId: languageId
        });

        return this._aiService
            .composePost(args)
            .pipe(
                map(chatId => {
                    const subject = new Subject<ReceiveChatMessageDto>();
                    this._chats[chatId] = subject;
                    const chat = new CurrentChatValues<CreatePostFormValues>();
                    chat.chatId = chatId;
                    chat.messageType = MessageTypeNames.Post;
                    chat.model = new CreatePostFormValues();
                    chat.model.init(args);
                    this._appSetting.saveCurrentChat(chat);
                    return {chat: chat, receiveMessage: subject};
                })
            );
    }
}
