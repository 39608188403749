import {BaseFormValues} from '../../../shared/settings/base-form-values';

export class CreatePostFormValues extends BaseFormValues {
    formName = 'Form.CreatePost';

    postTopic: string = undefined;
    channelId: number = undefined;
    postTypeId: number = undefined;
    targetGroupId: number = undefined;
    textLength: number = undefined;
    ageCategoryId: number = undefined;
    tonalityId: number = undefined;
    languageId: number = undefined;

    constructor(json?: string) {
        super();

        this.fromJsonString(json);
    }
}
