import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {AvatarDto} from '../../../shared/service-proxies/service-proxies';
import {LookupDataFilterService} from '../../../shared/session/lookup-data-filter.service';
import {AppComponentBase} from '../../../shared/app-component-base';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-edit-avatar',
    templateUrl: './edit-avatar.component.html'
})
export class EditAvatarComponent extends AppComponentBase {
    static createForm(formBuilder: FormBuilder): FormGroup {
        return formBuilder.group({
            aiModelId: [1, Validators.required],
            ageCategoryId: [0, Validators.required],
            tonalityId: [0, Validators.required],
            languageId: [0, Validators.required],
        });
    }
    @Input() avatar: AvatarDto;
    @Input() formGroup: FormGroup;
    constructor(injector: Injector,
                private _lookupFilterService: LookupDataFilterService,
                private _formBuilder: FormBuilder) {
        super(injector);
    }
}
