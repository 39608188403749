import {Component, Injector, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {EditAvatarComponent} from '@app/avatars/edit-avatar/edit-avatar.component';
import {MatDialogRef} from '@angular/material/dialog';
import {first, map} from '@node_modules/rxjs';
import {CreateMessageFormValues} from '@app/messages/create-message-dialog/create-message-form-values';
import {CreateMessageDto, MessageListDto, MessageServiceProxy} from '@shared/service-proxies/service-proxies';
import {OverviewService} from '@shared/session/overview.service';
import {ChatComponentBase} from '@shared/chat-component-base';
import {MessageTypeNames} from '@shared/settings/message-type-names';

@Component({
    templateUrl: './create-message-dialog.component.html',
    styleUrls: ['./create-message-dialog.component.scss', '../../../shared/chat-component-base.scss']
})
export class CreateMessageDialogComponent extends ChatComponentBase<CreateMessageFormValues> implements OnInit {

    step1FormGroup = this.formBuilder.group({
        personTypeId: [0, Validators.required],
        messageTypeId: [0, Validators.required],
        messageTopic: ['', Validators.required],
        textLength: ['3', Validators.required]
    });

    step2FormGroup = EditAvatarComponent.createForm(this.formBuilder);

    composedMessage = '';

    protected formGroups = [this.step1FormGroup, this.step2FormGroup];

    protected modelName = MessageTypeNames.Message;

    constructor(injector: Injector,
                dialogRef: MatDialogRef<CreateMessageDialogComponent>,
                private _messageService: MessageServiceProxy) {
        super(injector, dialogRef);
    }

    composeMessage() {
        if (this.currentChat && this.currentChat.chatId) {
            this.finalize(false);
        }
        this.startChat();
    }

    protected factory = () => new CreateMessageFormValues();

    protected startChatFunc = data => {
        return this.aiClient
            .composeMessage(data.messageTopic, data.messageTypeId, data.personTypeId, Number(data.textLength),
                data.ageCategoryId, data.tonalityId, data.languageId);
    }

    protected saveChatFunc = () => {
        const createDto = new CreateMessageDto();
        createDto.init(this.currentChat.model);
        createDto.topic = this.currentChat.model.messageTopic;
        createDto.text = this.composedMessage;

        return this._messageService
            .create(createDto)
            .pipe(
                first(),
                map(messageDto => {
                    const listDto = new MessageListDto();
                    listDto.init(messageDto);
                    this.overviewService.add(OverviewService.MessageModel, listDto);
                })
            );
    }

    protected onReceived = () => {
        const asisstantPrompts = this.prompts
            .filter(prompt => prompt.role === 'assistant');
        if (asisstantPrompts.length > 0) {
            this.composedMessage = asisstantPrompts[asisstantPrompts.length - 1].message;
        } else {
            this.composedMessage = '';
        }
    }
}
