import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-avatar-ctoa',
    templateUrl: './avatar-ctoa.component.html',
    styleUrls: ['./avatar-ctoa.component.scss']
})
export class AvatarCtoaComponent {
    @Input() text: string;
}
