import {Injectable} from '@angular/core';
import {PaymentServiceProxy} from '@shared/service-proxies/service-proxies';
import {BehaviorSubject, Observable} from 'rxjs';
import {first} from '@node_modules/rxjs';

@Injectable({
    providedIn: 'root'
})
export class PointBalanceService {

    pointBalance: number;

    private _pointBalance = new BehaviorSubject<number>(undefined);

    constructor(private _paymentService: PaymentServiceProxy) {
        abp.event.on('abp.notifications.received', (notification) => {
            if (notification.notification.notificationName !== 'PointBalance') {
                return;
            }

            const balance = notification.notification.data.properties.Balance;
            this._pointBalance.next(balance);
        });
    }

    getBalance(): Observable<number> {
        this._paymentService
            .getPointBalance()
            .pipe(
                first()
            )
            .subscribe(balance => {
                this._pointBalance.next(balance);
                this.pointBalance = balance;
            });
        return this._pointBalance;
    }
}
