import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-show-dialog',
  templateUrl: './show-dialog.component.html',
  styleUrls: ['./show-dialog.component.scss']
})
export class ShowDialogComponent {
    @Output() onDelete = new EventEmitter<void>();

    delete(): void {
        if (this.onDelete) {
            this.onDelete.emit();
        }
    }
}
