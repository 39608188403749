import {Component, Inject, Injector, OnInit} from '@angular/core';
import {ChatDto, ChatServiceProxy} from '@shared/service-proxies/service-proxies';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ShowDialogBase} from '@shared/components/show-dialog/show-dialog-base';
import {Observable, tap} from '@node_modules/rxjs';
import {LookupDataFilterService} from '@shared/session/lookup-data-filter.service';

@Component({
    selector: 'app-show-quick-chat-dialog',
    templateUrl: './show-quick-chat-dialog.component.html',
    styleUrls: ['../quick-chat-dialog/quick-chat-dialog.component.scss', './show-quick-chat-dialog.component.scss']
})
export class ShowQuickChatDialogComponent extends ShowDialogBase<ChatDto, ShowQuickChatDialogComponent> implements OnInit {

    topic: string;

    constructor(injector: Injector,
                private _lookupService: LookupDataFilterService,
                private _chatService: ChatServiceProxy,
                @Inject(MAT_DIALOG_DATA) private _data: { id: string },
                private _dialogRef: MatDialogRef<ShowQuickChatDialogComponent>) {
        super(injector, 'Chat', _data.id, _dialogRef);
    }

    ngOnInit(): void {
        this.onInit();
    }

    getData(): Observable<ChatDto> {
        return this._chatService
            .get(this._data.id)
            .pipe(
                tap(chat => {
                    if (!chat) {
                        return;
                    }
                    const entry = this._lookupService
                        .getCatalogEntry('textTypes', 'Chat', 'ChatTopic', chat.topicId);

                    if (!entry) {
                        return;
                    }

                    this.topic = this.l('selection_title_' + entry.key + '_' + entry.value);
                    const index = this.topic.indexOf(':');
                    if (index > 0) {
                        this.topic = this.topic.substring(index + 1);
                    }
                })
            );
    }

    deleteData(): Observable<void> {
        return this._chatService.delete(this._data.id);
    }

    public dataExists(): boolean {
        return !!(this.data && this.data.messages);
    }
}
