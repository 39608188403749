import {Component, Inject} from '@angular/core';
import {SelectionItem} from '../selection-item';
import {SelectDialogData} from '../select-dialog-data';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {SelectionCategory} from '@shared/components/selection/selection-category';

@Component({
    templateUrl: './select-dialog.component.html'
})
export class SelectDialogComponent {
    categories: SelectionCategory[] = [];
    showDescription = true;
    hasCategory = false;

    constructor(
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: SelectDialogData,
        private _bottomSheetRef: MatBottomSheetRef<SelectDialogComponent>,
    ) {
        this.categories = data.categories;
        this.showDescription = data.showDescription;
        this.hasCategory = data.hasCategory;
    }

    select(entry: SelectionItem): void {
        this._bottomSheetRef.dismiss(entry);
    }

    getImagePath(entry: SelectionItem): string {
        if (!entry) {
            return null;
        }
        return `/assets/selections/${entry.key}_${entry.value}.png`;
    }
}
